.reservation-modal {
  .loader {
    min-height: 200px;
  }
  .confirmation {
    div {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: #013A8F;
      margin-bottom: 10px;
    }
  }
  .tabledata {
    .tablerow {
      &:nth-child(odd) {
        background: #F5F9FF;
      }
      &>div:first-child {
        font-weight: 400;
        font-size: 16px;
        color: #9AA8BD;
      }
    }
  }
  .summary {
    .facility-data {
      img {
        border-radius: 10px 10px 10px 0px;
        width: 50%;
      }
      .business {
        font-weight: 700;
        font-size: 16px;
        color: #50596C;
      }
      .name {
        font-weight: 700;
        font-size: 16px;
        color: #000000;
      }
      .address {
        font-weight: 300;
        font-size: 14px;
        color: #696969;
      }
    }
  }
  h3 {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
  .modal-content {
    padding: 0 1rem !important;
  }
  .service-description {
    background-color: #F5F8FF;
    border-radius: 4px;
    font-size: 14px;
  }
  .header-text {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
  }
  .modal-header {
    color: #0D429F !important;
    font-weight: 600;
    font-size: 16px;
  }
  .modal-body {
    color: #0D429F !important;
    margin-right: 30px;
    margin-left: 30px;
  }

  .hours {
    .col {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;

      .slot {
        font-size: 12px;
        padding: 0.3rem;
        margin: 0.1rem;
        text-align: center;
        background: #F4F9FF;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          color: white;
          background-color: #0067FF;
         }
        &.selected {
          color: white;
          background-color: #0067FF;
        }
        &.selected:hover {
          color: white;
          background-color: #0067FF;
        }
        
      }
    }
  }
  .slick-slide {
    padding: 1px;

    .item {
      cursor: pointer;
      border-radius: 9px;
      text-align: center;
      background-color: #F5F9FF;

      .month {
        font-size: 14px;
        font-weight: 600;
      }

      .year {
        font-size: 12px;
        font-weight: 300;
      }

      .day-shortcut {
        text-transform: uppercase;
        font-size: 12px;
      }

      .day-number {
        font-size: 18px;
        font-weight: 600;
      }

      &:hover {
        color: white;
        background-color: #0067FF;
       }

      &.selected {
        color: white;
        background-color: #0067FF;
      }
      &.selected:hover {
        background-color: #0067FF;
      }
    }
 }
}

.reservation-slider {
  font-size: 14px;
  .slick-slide {
    padding: 1px;
  }

  .slick-next::before {
    color: #a5a5a5;
    opacity: 1;
  }

  .slick-prev::before {
    color: #a5a5a5;
    opacity: 1;
  }
}
.month-slider {
  text-transform: capitalize;
}