.card {
  box-shadow: 0px 3px 25px rgba(230, 236, 245, 0.65), 0px 0.375647px 10.0172px rgba(230, 236, 245, 0.03);
  border-radius: 16px;
  color: #696969;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  img {
    height: 92px;
  }
}