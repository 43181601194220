.facility-details {
  .divider {
    border: 1px solid #E5EFFF;
  }
  .opening-hours-container {
    max-width: 293px;
    width: 293px;
  }
  .socials {
    color: #A6A6A6;
    .social {
      text-decoration: none;
      span {
        font-size: 12px;
        color: #A6A6A6;
      }
    }
  }
  .phone {
    .label {
      font-size: 14px;
    }
    .value {
      font-size: 16px;
    }
  }
  .opening-hours {
    font-size: 16px;
    .closed {
      color: #A6A6A6;
    }
  }
  .name {
    font-size: 24px;
  }

  .title1 {
    font-size: 20px;
    font-weight: 600;
  }

  .address-text {
    color: #A6A6A6;
    font-size: 14px;
  }

  .address {
    color: #A6A6A6;
    font-size: 14px;
    background: #FAFCFF;
    border-radius: 8px;

    .title {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
    }
    .icon {
      background-color: white;
      width: 60px;
      height: 60px;
      border-radius: 160px;
      cursor: pointer;
    }
  }

  .map {
    height: 369px;
  }
}