.wrapper.mobile {
  background-position-x: center;
  background-repeat-y: no-repeat;
  .container {
    margin-left: 0;
    margin-right: 0;
    border-radius: 26px 26px 0px 0px;
    width: 100%;
    max-width: 100%;
    padding-bottom: 90px;
  }

  header {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    img {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  .search-form {
    border-radius: 12px;
    .MuiFormControl-root {
      color:#013A8F;
    }
    label, svg {
      font-weight: 400;
      color:#013A8F;
    }
    .MuiInputAdornment-root, svg {
      filter: invert(13%) sepia(98%) saturate(2872%) hue-rotate(209deg) brightness(92%) contrast(102%);
    }
    .MuiFormControl-root {
      border-radius: 12px;
    }
    .MuiOutlinedInput-root, .MuiOutlinedInput-input, .MuiInputBase-root {
      border-radius: 12px;
      background-color: #EDF4FF;
    }
    fieldset {
      border-radius: 12px;
      border: none !important;
    }
  }
  .partner-info {
    //padding: 5px !important;
    .info {
      width: 100% !important;
    }
    img {
      display: none;
    }
  }
}

.mobile.appdialog {
  z-index: 1000;
  .MuiDialog-container {
    align-items: start;
    height: calc(100% + 64px);
    .MuiPaper-root {
      max-width: 100%;
      border-radius: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      .dialog-header {
        display: none;
      }
      .header {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: 600;
        color: #003D98;
        svg {
          color: #003D98;
        }
        img, svg {
          position: absolute;
          left: 35px;
          top: 35px;
        }
        display: flex;
        justify-content: center;
      }
    }
  }
  .search-form {
    border-radius: 12px;
    .MuiFormControl-root {
      color:#013A8F;
    }
    label, svg {
      font-weight: 400;
      color:#013A8F;
    }
    .MuiInputAdornment-root, svg {
      filter: invert(13%) sepia(98%) saturate(2872%) hue-rotate(209deg) brightness(92%) contrast(102%);
    }
    .MuiFormControl-root {
      border-radius: 12px;
    }
    .MuiOutlinedInput-root, .MuiOutlinedInput-input, .MuiInputBase-root {
      border-radius: 12px;
      background-color: #EDF4FF;
    }
    fieldset {
      border-radius: 12px;
      border: none !important;
    }
    input {
      color:#013A8F;
    }
  }
}