.testInfo {
  position: absolute;
  bottom: 0;
  z-index:9999999;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    z-index:999999;
    background-color: orange;
    color: white;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    svg {
      cursor: pointer;
      min-width: 30px;
    }
  }
}