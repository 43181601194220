.appdialog {
  & > .MuiBackdrop-root {
    background: rgba(132, 174, 255, 0.25) !important;
    opacity: 1 !important;
  }
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: 700px;
      border-radius: 12px;
      box-shadow: 0 0 5px rgba(0, 87, 255, 0.05), 0 25px 35px rgba(48, 81, 147, 0.08);
      width: 100% !important;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;

      .dialog-header {
        border-bottom-color: white;
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        color: #0048b2;
        font-size: 20px;
      }

      .dialog-footer {
        .buttons {
          display: flex;

          button {
            flex: 1 1;

            &:not(:first-child) {
              margin-left: 10px;
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }

      svg.close {
        color: #C8C8C8;
        cursor: pointer;
        position: absolute;
        right: 22px;
        top: 22px;
      }
    }
  }
}