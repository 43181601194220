.btn {
  background: #156b5c;
  color: #fff;
  border: none;
  position: relative;
  height: 40px;
  font-size: 1em;
  padding: 0 1em;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
}

.btn:hover {
  background: #fff;
  color: #156b5c;
}


.btn-signin-signup {
  background: transparent;
  color: #fff;
  border: none;
  position: relative;
  height: 40px;
  font-size: 1em;
  padding: 0 1em;
  cursor: pointer;
  transition: 800ms ease all;
  outline: none;
}

.btn-signin-signup:hover {
  background: #fff;
  color: #156b5c;
}

.btn-signin-signup:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #156b5c;
  transition: 400ms ease all;
}

.btn-signin-signup:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.btn-signin-signup:hover:before {
  width: 100%;
  transition: 800ms ease all;
}

.btn-search {
  height: 39px;
  outline: 0;
  text-align: center;
  line-height: 42px;
  border: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
}

.btn-search:hover {
  -webkit-transition: background-color .3s ease;
  transition: background-color .3s ease;
}

.for-partners {
  white-space: nowrap;
  border-radius: 12px;
  background-color: #FF9900;
  color: white;
  font-weight: bold;
}

.btn-reserve {
  background-color: #1D6AFF !important;
}