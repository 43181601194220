header {
  position: relative;
  width: 100%;

  .MuiInputLabel-root {
    &.Mui-focused, &.MuiFormLabel-filled {
      display: none;
    }
  }

  &.business-context {
      margin-bottom: 0 !important;
    .navbar {
      position: inherit !important;
      background-color: #f7f9fa !important;
      .link {
        color: gray !important;
      }
    }
  }

  .navbar {
    width: 100%;
    z-index: 999999;
    top: 0;
    position: fixed !important;

    .navbar-brand {
      display: flex;
      align-items: center;

      img {
        width: 80%;
      }
    }

    .form-field {
      margin: 0;
    }

    .link {
      color: white;
      cursor: pointer;
      white-space: nowrap;
    }

    &.active {
      background-color: #0033C9;
    }
  }

  &:not(.with-background) {
    margin-bottom: 91px;

    .navbar {
      background-color: #0033C9;
    }
  }

  &.with-background {
    min-height: 469px;
    height: 469px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url('../assets/img/header_background.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  .facility-types-list {
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;

    div {
      cursor: pointer;
    }
  }

  .search-container-main {
    position: absolute;
    top: 200px;

    p {
      color: white;
    }

    width: 100%;
    display: flex;
    justify-content: center;
  }

  .search {
    text-align: center;
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .inputs {
      padding-left: 30px;
      padding-right: 30px;
      max-width: 1000px;
      width: 100%;
      .form-field {
        width: 200px;
        &:first-child {
          width: 300px;
        }
        &:not(:first-child) {
          select {
            height: 100%;
            border-radius: 0;
          }
        }
        &:nth-child(2) {
          //width: 100%;
        }
        &:first-child {
          .MuiFormControl-root {
            border-radius: 12px 0 0 12px !important;

            .MuiOutlinedInput-root {
              border-radius: 12px 0 0 12px !important;

              input {
                border-radius: 12px 0 0 12px !important;
              }
            }
          }

          fieldset {
            border: 0 !important;
          }
        }

        &:nth-child(2), &:nth-child(3) {
          .MuiFormControl-root, .MuiAutocomplete-root {
            border-radius: 0 !important;

            .MuiOutlinedInput-root {
              border-radius: 0 !important;

              input {
                border-radius: 0 !important;
              }
            }
          }

          fieldset {
            border-radius: 0 !important;
            border: 0 !important;
            border-left: 1px solid #9EA4AD !important;
          }
        }

        button {
          background-color: #FF9900 !important;
          border-radius: 0 12px 12px 0 !important;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          height: 100%;
        }
      }
    }
  }
}

.place-select {
  .MuiAutocomplete-endAdornment {
    display: none;
  }
}