.datatable {
  background-color: white;
  .table {
    margin-bottom: 0;
    color: #00359B;
    border-radius: 10px;
    margin-top: 40px;

    .counter-column {
      width: 1%;
    }

    .avatar-column {
      width: 1%;
    }

    thead {
      tr {
        th {
          border-top: none;
          border-bottom: none;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.05em;

          &:first-child {
            padding-left: 30px;
          }

          &:last-child {
            max-width: 50px;
          }
        }
      }
    }

    tbody {
      border-top: 0 !important;
      background: #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 0px 5px rgba(0, 87, 255, 0.05), 0px 25px 35px rgba(48, 81, 147, 0.08);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      tr {
        &.skeleton {
          td {
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        td {
          border-top: none;
          vertical-align: middle;

          &:first-child {
            padding-left: 30px;
          }

          &:last-child {
            max-width: 50px;
          }
        }

        &:nth-child(even) {
          background: #F9FBFF;
        }
      }
    }

    button {
      background-color: transparent !important;
      color: #1D6AFF !important;
    }

    tbody tr:first-child td:first-child {
      border-top-left-radius: 10px;
    }

    tbody tr:first-child td:last-child {
      border-top-right-radius: 10px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    padding: 27px;

    .prev, .next {
      background-color: #F1F6FF;
      color: #00359B;
      border-radius: 8px;
      width: 26px;
      height: 26px;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pages {
      display: flex;
      flex-direction: row;

      .page {
        border-radius: 8px;
        color: #00359B;
        font-size: 14px;
        line-height: 16px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &.current {
          color: #1D6AFF;
          background-color: #EAF1FF;
        }
      }
    }
    .length {
      color: #00359B;
      .nav-link {
        background: #F1F6FF;
        border-radius: 5px;
        span {
          padding-right: 7px;
        }
      }
      .dropdown-menu {
        min-width: 20px;
      }
    }
  }
}