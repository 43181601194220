
.profile-nav {
  .row {
    flex-wrap: nowrap;
  }
  max-height: 380px;
  min-height: calc(100vh - 255px);
  p.active {
    background-color: red;
  }
  p {
  }
  .nav-item {
    &:hover {
      cursor: pointer;
    }
    background-color: #F9F9F9;
    border-radius: 5px;
    width:100%;
    margin: 1px;
    svg {
      float: right;
      color: gray;
    }
    height:50px;
    padding-top:12px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    &.active {
      background-color: #DADADA;
      svg {
        color: black;
      }
    }
    a {
      color: black;
    }
    a:hover {
      text-decoration: none;
    }
  }
}
