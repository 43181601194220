.image-gallery {

  .image-gallery-slides {
    border-radius: 16px;
  }

  .image-gallery-thumbnail {
    border-radius: 8px;
  }

  .nav {
    position: absolute;
    top: calc(50% - 20px);
    z-index: 99999;
    cursor: pointer;

    &.right-nav {
      right: -28px;
      transform: rotate(180deg);
    }

    &.left-nav {
      left: -24px;
    }
  }

}