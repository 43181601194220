@import "./var.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./header";
@import "./footer";
@import "./button";
@import "./reservationmodal";
@import "./profile";
@import "./facility";
@import "./imageslider";
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import "../core/scss/main";
@import 'mobilenavigation.scss';
@import "mobile";

.service-list {
  max-width: 500px;
  width: 500px;
  display: flex;
  flex-direction: column;

  .divider {
    border: 1px solid #E4EBF4;
    width: 100%;
  }

  .service-item {
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;

    div:first-child {
      width: 100%;
    }

    div:nth-child(2) {
      min-width: 100px;
      text-align: right;
      margin-right: 20px;
    }

    .price {
      text-align: right;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
    }

    button {
      margin: 10px;
      background-color: #1D6AFF;
      color: white;
      font-size: 15px;
      border-radius: 5px;
      outline: none;
      border: none;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      height: 32px;
    }
  }
}

.vhidden {
  visibility: hidden !important;
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

body, html {
  font-family: "Poppins";
  min-height: 100%;
  height: 100%;
  background-color: #f7f9fa;
}

.container {
  background-color: white;
  min-height: calc(100vh - 10vh - 91px);
}

.terms-text {
  color: #828282;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  flex: 2;
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  @media(max-width: $sizeMobile) {
    overflow-y: auto;
    padding-bottom: 50px;
    margin-left: 0;
    margin-right: 0;
  }
}

svg {
  margin-right: 5px;
}


.hidden {
  display: none;
}

.facility-slider-container {
  .title {
    font-size: 30px;
    line-height: 45px;
    font-size: 30px;
    color: black;

    .inverse {
      color: #0067FF;
    }
  }
}

.facility-slider {
  width: 90%;

  .slick-next::before {
    font-size: 30px;
    color: #000000;
    opacity: 1;
  }

  .slick-prev::before {
    font-size: 30px;
    color: #000000;
    opacity: 1;
  }
}

.invite-object {
  background-color: #EFF5FF;
  border-radius: 16px;

  img {
    bottom: 8px;
    left: 35px;
  }

  .text {
    font-size: 20px;
    color: #044FBD;
  }
}

.partner-info {
  background-color: #0067FF;
  border-radius: 16px;
  font-size: 18px;
  color: white;

  button {
    height: 65px;
    background-color: white !important;
    width: 100%;
    color: #0067FF !important;
  }

  .info {
    width: calc(100% - 300px) !important;
  }

  img {
    right: 45px;
    bottom: 50px;
  }
}

//.featured-facilities {
//  margin-top: 30px;
//  background-color: #ffffff;
//  border-radius: 10px;
//  padding-top: 15px;
//  padding-bottom: 25px;
//
//  p {
//    text-align: center;
//    font-size: 16px;
//    font-weight: bold;
//  }
//
//}

input.form-control {
  border: none;
  border-bottom: 2px solid;
  border-radius: 0;
  border-color: gray;
  box-shadow: none !important;

  &:focus {
    outline: none;
    border-color: $color1 !important;
  }
}

.login-modal {
  p {
    color: #003D98 !important;
    font-size: 16px;
  }
}

.reservation-tile {
  padding: 10px;
  font-size: 12px;
  color: #0D429F;

  .reservation-content {
    width: 100%;
    height: 100%;
    box-shadow: 0px 3px 25px rgba(230, 236, 245, 0.46), 0px 0.375647px 10.0172px rgba(230, 236, 245, 0.03);
  }

  .date {
    background: #F5F9FF;
    border-radius: 4px;

    .day-shortcut {
      font-size: 14px;
    }

    .day {
      font-size: 20px;
    }

    .time {
      font-size: 14px;
    }
  }


  .status {
    width: 100%;
    height: 46px;
    border-radius: 9px;

    .icon-collapse {
      transition: transform .2s ease-in-out;
    }

    &.show {
      .icon-collapse {
        transform: rotate(180deg);
      }
    }

    .nav-link {
      width: 100%;
      text-align: center;
      height: 100%;
      color: #17B183;
      font-size: 14px;
      border-radius: 9px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      &.left {
        justify-content: left;

        div.text {
          width: 100%;
          text-align: center;
        }
      }

      .bg-icon {
        padding: 7px;
        border-radius: 26px;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.confirmed {
        color: #17B183;
        background-color: #D1FFF1;

        svg {
          color: #17B183;
        }

        .bg-icon {
          background-color: #B9F3E2;
        }
      }

      &.cancelled {
        background-color: #FFEBEB;
        color: #E81515;

        svg {
          color: #E81515;
        }

        .bg-icon {
          background-color: #FFE1E1;
        }
      }

      &.finished {
        background-color: #DCE8FF;
        color: #155CE7;

        svg {
          color: #155CE7;
        }

        .bg-icon {
          background-color: #CDDEFF;
        }
      }

      &.waiting_for_business_approval {
        background: #FFF3CD;
        color: #D19A0B;

        svg {
          color: #D19A0B;
        }

        .bg-icon {
          background: #F9E9B1;
        }
      }

      &.rejected {
        background: #FFE7C2;
        color: #DA6D00;

        svg {
          color: #DA6D00;
        }

        .bg-icon {
          background: #FFD696;
        }
      }
    }

    .dropdown-menu-lg {
      width: 100%;
      border: none;
      box-shadow: 0px 20px 51px rgba(85, 101, 171, 0.06), 0px 0.375647px 6.01034px rgba(85, 101, 171, 0.06);
      border-radius: 0px 0px 10px 10px;

      .menu-item {
        padding: 8px;

        button {
          height: 100%;
          width: 100%;
          border-radius: 5px;
          text-align: center;
          font-size: 12px;
          padding: 8px;
          cursor: pointer;

          &.confirmed {
            color: #17B183;
            background-color: #E2FFF6;
          }

          &.cancelled {
            background-color: #FFEBEB;
            color: #E81515;
          }
        }
      }
    }
  }
}

.facility-image-slider {
  margin-bottom: 20px;

  img {
    width: 80%;
    margin: auto;
  }
}

.facility-slider-item {
  margin: auto;
  width: 200px;
  cursor: pointer;
}

.facility-slider-item-card {
  position: relative;
  margin: auto;

  .image-container {
    display: flex;
    justify-content: center;
  }

  img {
    max-height: 135px;
  }

  .details {
    .name {
      font-size: 14px;
    }

    .address {
      font-size: 12px;
      color: #696969;
    }
  }

  .favourite {
    position: absolute;

    svg {
      color: red;
    }
  }

  p {
    text-align: left;
    font-weight: normal;
    font-size: 10px;
    padding: 0;
    margin: 0;
  }

  img {
    border-radius: 16px 16px 16px 0px;
  }
}

.map-container {
  display: inline-block;

  .map {
    top: 80px;
    position: sticky;
    width: 100%;
    height: calc(100vh - 100px);
    margin-right: 50px;

    .leaflet-container {
      height: 100%;
    }

    @media(max-width: $sizeMobile) {
      display: none;
    }


    .others-facilities {
      width: 100%;
      height: 200px;
      @media(max-width: $sizeMobile) {
        display: none;
      }
    }
  }

}

.map {
  border-radius: 8px;
  position: relative;

  .leaflet-container {
    border-radius: 8px;
  }

  &.fullscreen {
    z-index: 9999999;
    position: fixed;
    top: 2vh;
    left: 1vw;
    height: 96vh;
    width: 97vw !important;
  }

  .fullscreen-icon {
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    top: 12px;
    right: 8px;
  }
}

.leaflet-right {
  height: 18px;
}

.leaflet-control-zoom {
  border: none !important;
}

.search-result-item {
  position: relative;

  .unfavourite {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }

  @media(max-width: $sizeMobile) {
    flex-direction: column;
  }

  &.hoverable:hover {
    background-color: #ece8e8;

    .service-table-row {
      background-color: #ece8e8;
    }
  }

  .details {
    padding: 10px;

    img {
      border-radius: 5px;
      width: 300px;
      margin: auto;
    }

    p {
      margin-bottom: 0;
    }

    width: 350px;
  }

  .services {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .navigate-to-object {
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    cursor: pointer;
    border-radius: 100px;
  }
}

.loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.popup-me {
  height: 20px !important;
}

.icon-input {
  background-color: white;
  border-radius: 0;
  border-right-style: solid;
  border-width: 1px;
  display: inline-block;
  color: #30A591;
  padding: 2px 2px 2px 4px;

  input {
    border-radius: 3px;
    border: none;
    outline: none;
  }
}

.autocomplete-input {
  display: inline-block;

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background-color: white;
    color: black;
    width: 284px;

    span {
      text-align: left;

      &:hover {
        background-color: lightgray;
      }
    }
  }
}

.facility-type-list {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  overflow-y: auto;
  padding: 1vh;
  padding-top: 40px;
  padding-bottom: 40px;

  div.item {
    cursor: pointer;
    max-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.disabled {
      filter: grayscale(100%);
    }

    .image {
      position: relative;

      img {
        max-height: 48px;
      }
    }

    .name {
      color: #013A8F;
      text-align: center;
      font-size: 12px;
    }

    .count {
      position: absolute;
      top: -8px;
      left: -8px;
      font-size: 10px;
      font-weight: 500;
      color: white;
      background-color: black;
      border-radius: 10px;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.notifications-dropdown {
  font-size: 10px;

  button {
    background: none !important;
    box-shadow: none !important;

    &:hover {
      background: none;
    }

    @media(max-width: $sizeMobile) {
      color: white;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $color2;
    }
  }

  .notification-count {
    color: red;
    font-weight: bold;
    font-size: 18px;
  }

  .notification {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;

    .notification-content {
      display: flex;
      flex-direction: row;
      background-color: #EDEDED;
      padding: 10px;

      .contentt {
        p {
          color: black;
        }
      }

      .close {
        button {
          font-size: 15px;
          color: black;
          margin: auto;
          width: 10px;
        }
      }
    }

    &.active, &:active {
      color: black;
      background-color: white;
    }

    &:hover {
      background-color: white;
    }
  }
}

.dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
}

.leaflet-marker-icon {
  height: 22px;
  width: 22px;
}

button.btn-reserve {
  background-color: #1D6AFF !important;
  font-weight: normal !important;
}

.bordered-container {
  border: 0.5px solid #BECBDF;
  border-radius: 8px;
  position: relative;
  width: 100%;

  .title {
    top: -13px;
    color: black;
    font-weight: 600;
    font-size: 20px;
    position: absolute;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.w-fit {
  width: fit-content;
}

.shadowed {
  box-shadow: 0px 4px 28px #F0F6FF;
}

.services-table {
  border-bottom: 1px solid #858585;
}

.business-page {
  .container {
    padding: 0;
  }

  .header {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 32px;
    font-weight: 600;
    border-bottom: 1px solid #E4EBF4;

    .btn {
      padding: 13px;
      border-radius: 5px;
      background: #DFEAFF;
      height: 46px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #1D6AFF;
    }
  }

  .menu {
    border: 1px solid #E4EBF4;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    color: #0033C9;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 7px;

    .menu-item {
      margin: 10px;
      border-radius: 5px;
      padding: 10px;

      &.disabled {
        color: gray !important;
        cursor: unset !important;
      }

      &.active {
        background-color: #ECF1FF;
      }

      a {
        text-decoration: none;
      }
    }

    .divider {
      border: 1px solid #E4EBF4;
      height: 27px;
    }
  }

  .page {
    height: 100%;
    border-top: 1px solid #E4EBF4;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;

    .heading {
      font-weight: 600;
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
    }

    .page {
      border-radius: 5px;
      height: 100%;
      min-height: 100%;
      border: 1px solid #E4EBF4;
      padding-left: 40px;
      padding-right: 40px;
    }

    .trainings-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .details-page {
      padding-bottom: 100px;

      .address {
        margin-top: 10px;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #F4F9FF;
        background: #FFF;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        div:first-child {
          flex: 1;
        }

        .address-text {
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          display: flex;
          flex-direction: column;

          div:first-child {
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
          }
        }

        .divider {
          background: #E5EFFF;
          width: 1px;
          height: 76px;
          margin-right: 20px;
        }

        .pointer {
          cursor: pointer;
          border-radius: 70px;
          width: 43px;
          height: 43px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #EEF5FF;
          box-shadow: 0px 0.37564656138420105px 10.017241477966309px 0px rgba(230, 236, 245, 0.03), 0px 3px 25px 0px rgba(230, 236, 245, 0.46);

          svg {
            margin-left: 5px;
          }
        }
      }

      .contacts {
        margin-top: 50px;
        width: 100%;
        border-radius: 9px;
        border: 1px solid #BECBDF;

        .phone {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-bottom: 1px solid #BECBDF;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 16px;
          gap: 15px;

          .number {
            display: flex;
            flex-direction: row;
            text-decoration: none;
            align-items: center;
            gap: 5px;
            font-weight: 700;
            font-size: 16px;
            color: black;
          }
        }

        .socials {
          justify-content: space-around;
          font-size: 12px;
          display: flex;
          flex-direction: row;
          padding-top: 20px;
          padding-bottom: 20px;

          a {
            text-decoration: none;
            color: black;
          }
        }
      }

      .opening-hours {
        color: #000;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        max-width: 300px;
        min-width: 300px;

        .days {
          div {
            padding: 5px;

            &.active {
              font-weight: 800;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          span {
            &.inavailable {
              color: #A6A6A6;
            }
          }
        }
      }

      .description {
        color: #3D3D3D;
        font-size: 16px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
      }

      .sports {
        border-radius: 8px;
        border: 0.5px solid #BECBDF;
        padding: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        flex-direction: row;
        position: relative;
        margin-top: 30px;

        .sport {
          margin-left: 10px;
          break-inside: avoid;
        }

        .heading {
          padding: 10px;
          width: 250px;
          background-color: white;
          text-align: left;
          position: absolute;
          left: 60;
          top: -57px;
          color: #000;
          font-size: 20px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .rental-page {
      .row {
        .col {
          font-size: 16px;
          font-weight: 600;
          color: #002678;
          padding: 30px;

          .btn {
            height: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: #1D6AFF;
            background-color: #F5F8FF;
            width: 100%;
          }
        }
      }
    }
  }
}

.facility-page {
  padding: 40px;

  .customfields {
    tr {
      td:first-child {
        font-weight: bold;
        padding: 10px;
        padding-right: 30px;
      }
    }

    border-radius: 8px;
    border: 0.5px solid #BECBDF;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
    position: relative;
    margin-top: 30px;

    .heading {
      padding: 10px;
      width: 250px;
      background-color: white;
      text-align: left;
      position: absolute;
      top: -25px;
      color: #000;
      font-size: 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .btn {
    padding: 13px;
    border-radius: 5px;
    background: #DFEAFF;
    height: 46px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #1D6AFF;
  }

  h3 {
    margin-top: 20px;
    padding-left: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  .terms {
    padding-top: 30px;

    h3 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }

    button {
      color: white;
    }

    .navigation {
      align-items: center;
      gap: 10px;
      margin-top: 40px;
      margin-bottom: 40px;

      button {
        background-color: #F4F9FF !important;
        font-size: 15px;
        color: #0D429F !important;
        border-radius: 5px;
      }
    }

    table.rental-table {
      td:not(:first-child) {
        max-width: 14px;
      }

      tr.header {
        td:not(:first-child) {
          text-align: left;
        }
      }

      tr:not(.header) {
        td:not(:first-child) {
          div {
            cursor: pointer;
          }
        }
      }

      td {
        border: 1px solid #E1E1E1;
        width: 64px;
        min-width: 54px;
        height: 40px;

        div {
          width: 100%;
          height: 100%;
          background-color: #004ADB;
          color: #FFF;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
        }

        div.active {
          background-color: #0066FF;
        }
      }

      tr {
        td:first-child {
          text-align: right;
          background-color: white;
          color: #000;
          font-size: 16px;
          font-weight: 400;
          padding-left: 10px;
          padding-right: 10px;
          white-space: nowrap;
        }
      }

      tr.header {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        td:first-child {
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        td {
          background-color: white;
          color: black;
        }
      }
    }
  }
}

.rental-summary-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 175px;
  transition: all 0.2s;
  border-top: 1px solid #B0B0B0;

  &.close {
    height: 0;
  }

  .container {
    padding-top: 0;
    height: 175px;
    min-height: 175px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #3E3E3E;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;

    div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-evenly;
    }

    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: space-evenly;

      div {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }

    .price {
      color: #1D6AFF;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }

    .facility-info {
      color: #0D429F;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
}

.page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .btn {
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D6AFF;
    background-color: #F5F8FF;
  }

  p {
    font-weight: 700;
    font-size: 25px;
    margin: 50px;
    text-align: center;
  }
}

.rentalcalendar {
  position: relative;

  .term-table {
    width: 100%;
    display: table;

    .time {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 21px;
    }

    .day-header {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
      line-height: 18px;
    }

    .slot {
      background-color: rgba(255, 255, 255, 1);

      &.inactive {
        background-color: lightgray;
      }

      &.active {
        cursor: pointer;
      }

      &.hovered {
        background-color: blue;
      }

      &.hover-top {
        border: 0 !important;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      &.hover-bottom {
        border: 0 !important;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &.hover-middle {
        border: 0 !important;
      }
    }

    .term-table-row {
      display: table-row;

      .term-table-column {
        display: table-cell;
        border: 1px solid lightgray;
      }

      &:first-child {
        .term-table-column {
          border-top: 0;
        }
      }

      .term-table-column:first-child {
        border-left: 0;
        max-width: 50px;
      }

      .term-table-column:last-child {
        border-right: 0;
      }
    }
  }
}

.rentalmodal {
  .dialog-footer {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  .dialog-body {
    min-height: 200px;
  }

  .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .info {
      color: rgba(111, 108, 144, 1);
      font-size: 12px;
      margin-bottom: 30px;
    }
  }

  .rentaltype {
    display: flex;
    flex-direction: row;
    justify-content: normal;
    gap: 20px;

    & > div {
      display: flex;
      flex-direction: row;
      flex: 1;
      border-radius: 16px;
      padding: 20px;
      cursor: pointer;
      align-items: center;
      border: 1px solid rgba(239, 240, 246, 1);

      &.active {
        border: 1px solid rgba(74, 58, 255, 1);
      }
    }
  }
  .rentalinfo {
    border: 1px solid lightgray;
    margin-bottom: 15px;
    border-radius: 18px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .service {
      font-size: 18px;
      color: rgba(23, 15, 73, 1);
    }

    .time {
      font-size: 18px;
      color: rgba(111, 108, 144, 1);
    }

    .price {
      font-size: 25px;
      line-height: 20px;
    }

    div:first-child {
      display: flex;
      flex-direction: column;
    }
  }
  .term-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
    .term {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.04);
      box-shadow: 0px -2px 4px 0px rgba(20, 20, 43, 0.02);
      box-shadow: 0px 3px 14px 0px rgba(74, 58, 255, 0.03);
      border: 1px solid gray;
      color: rgba(111, 108, 144, 1);
      border-radius: 10px;
      font-size: 18px;
      line-height: 30px;
      &.invalid {
        background-color: rgba(248, 229, 229, 1);
      }
      .price {
        font-size: 25px;
        color: rgba(23, 15, 73, 1);
      }
    }
  }

  .rentalform {
    .cycle-form {
      .heading {
        color: rgba(23, 15, 73, 1);
        text-align: center;
        font-size: 20px;
        line-height: 35px;
      }
      .days {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        text-align: center;
        margin-bottom: 15px;
        .day {
          background-color: rgba(255, 255, 255, 1);
          border-radius: 16px;
          border: 2px solid rgba(226, 226, 226, 1);
          padding: 10px;
          min-width: 60px;
          &:not(.current) {
            cursor: pointer;
          }
          &.active {
            border: 2px solid rgba(74, 58, 255, 1);
          }
          &.current {
            background-color: rgba(75, 59, 255, 0.05);
            border: 2px solid rgba(74, 58, 255, 1);
          }
        }
      }
      .cycle-type {
        display: flex;
        flex-direction: row;
        gap: 10px;
        div {
          padding: 10px;
          text-align: center;
          flex: 1;
          border-radius: 16px;
          border: 1px solid gray;
          cursor: pointer;
          &.active {
            border: 1px solid blue;
          }
        }
      }
    }
  }

  .przelewy24 {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .paymentmethod {
      max-width: 110px;
      border: 4px solid lightgray;
      cursor: pointer;
      border-radius: 10px;
      flex-grow: 1;

      &.active {
        border: 4px solid gray;
      }

      img {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }
    }
  }
}