footer {
  font-size: 16px;
  color: #C6C6C6;
  width: 100%;
  background-color: #000000;
  padding: 2rem;

  .links {
    a {
      color: #C6C6C6;
      text-decoration: none;

      &:not(:first-child) {
        border-left: 1px solid #C6C6C6;
      }
    }
  }
}
