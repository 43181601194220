.mobile-navigation {
  background-color: #0067FF;
  height: 80px;
  width: 100%;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  position: fixed;
  bottom: 0;
  padding-top: 15px;
  img {
    margin: 5px;
  }
  .item {
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    &.active {
      .imgcontainer {
        background-color: white;
        img {
          filter: invert(32%) sepia(88%) saturate(5794%) hue-rotate(212deg) brightness(104%) contrast(105%);
        }
        border-radius: 100px;
      }
    }
  }
}